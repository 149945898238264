import React from 'react';
import { Card, Button } from '@mui/material';

function Dashboard() {
  return (
    <div>
      v0.2.8
      <Card className='p20'>
        <Button
          size="small"
          variant="text"
          color="secondary"
          href="/#/panel/AdzyApi/ImageSearchForLicense?page=1&perPage=50&sort=id&sortOrder=ASC&defaultValues=%7B%22hasNoLicenseKey%22%3Atrue%2C%22source%22%3A%7B%22id%22%3A%22604e9263472b1adeaeede266%22%2C%22name%22%3A%22shutterstock%22%2C%22isActive%22%3Atrue%7D%2C%22sourceId%22%3A%22604e9263472b1adeaeede266%22%7D"
        >
          ShutterStock Images with no License Key
        </Button>
      </Card>
    </div>
  );
}

export default Dashboard;
