// Auto generated file
import { globalEnums } from 'onka-react-admin-core';

const paymentApiEnums = {
  ...globalEnums,
  ...{

    PriceBillingScheme : {
            PerUnit: 1, // 
            Tiered: 2, // 
    },
    PriceTiersMode : {
            None: 1, // 
            Graduated: 2, // 
            Volume: 3, // 
    },
    PriceType : {
            Onetime: 1, // 
            Recurring: 2, // 
    },
    PriceRecurringInterval : {
            none: 1, // 
            Day: 2, // 
            Week: 3, // 
            Month: 4, // 
            Year: 5, // 
    },
    PriceRecurringUsageType : {
            None: 1, // 
            Metered: 2, // 
            Licensed: 3, // 
    },
    PriceRecurringAggregateUsage : {
            None: 1, // 
            Sum: 2, // 
            LastDuringPeriod: 0, // 
            LastEver: 0, // 
            Max: 0, // 
    },
    SubscriptionStatus : {
            InComplete: 1, // 
            Active: 2, // 
            Cancelled: 3, // 
            Unpaid: 4, // 
            Trailing: 5, // 
            InCompleteExpired: 6, // 
    },
    InvoiceStatus : {
            Draft: 1, // 
            Open: 2, // 
            Paid: 3, // 
            Void: 4, // 
            Uncollectible: 5, // 
    },
    CardCvcCheck : {
            Pass: 1, // 
            Fail: 2, // 
            Unavailable: 3, // 
            Unchecked: 4, // 
    },
    CardFunding : {
            Credit: 1, // 
            Debit: 2, // 
            Prepaid: 3, // 
            Unknown: 4, // 
    },
    ChargeStatus : {
            Succeded: 1, // 
            Pending: 2, // 
            Failed: 3, // 
    },
    InvoiceBillingReason : {
            AutomaticPendingInvoiceItemInvoice: 1, // 
            Manual: 2, // 
            Subscription: 3, // 
            SubscriptionCreate: 4, // 
            SubscriptionCycle: 5, // 
            SubscriptionThreshold: 6, // 
            SubscriptionUpdate: 7, // 
            Upcoming: 8, // 
    },
    InvoiceItemType : {
            InvoiceItem: 1, // 
            Subscription: 2, // 
    },
  }
}
export default paymentApiEnums