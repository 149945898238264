// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import emailApiEnums from '../../emailApiEnums';

export const pageConfig = new PageConfig({
  route: '/EmailApi/EmailTemplateSearch',
  moduleKey: 'EmailApi',
  pageKey: 'EmailTemplate',
  menu: 'EmailApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: true,
  new: true,
  delete: false,
  export: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'settingId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'EmailApi/EmailSettingSearch',
        filterField: 'name',
        dataField: 'setting',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'name',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'title',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subject',
      validators: [Validators.required, Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'body',
      validators: [Validators.required, Validators.maxLength(8000)],
      fieldSize: 12,
      createComponent: CC.EditorComponent,
      editComponent: CC.EditorComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fromName',
      validators: [Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fromEmail',
      validators: [Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toEmail',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toCC',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toBcc',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isNotification',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'parameterList',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isActive',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'name'
    }),
    new PageField({
      name: 'title'
    }),
    new PageField({
      name: 'subject',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'fromName'
    }),
    new PageField({
      name: 'fromEmail'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'settingId',
      reference: {
        route: 'EmailApi/EmailSettingSearch',
        filterField: 'name',
        dataField: 'setting',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'title',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'subject',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isNotification',
      enumName: 'YesNo',
      enum: emailApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'isActive',
      enumName: 'YesNo',
      enum: emailApiEnums.YesNo,
      dataType: 'string'
    })
  ],
});

