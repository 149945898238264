// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as cardSearchRoute } from './pages/cardSearch';
import { route as invoiceSearchRoute } from './pages/invoiceSearch';
import { route as invoiceItemSearchRoute } from './pages/invoiceItemSearch';
import { route as priceSearchRoute } from './pages/priceSearch';
import { route as productSearchRoute } from './pages/productSearch';
import { route as subscriptionSearchRoute } from './pages/subscriptionSearch';
import { route as subscriptionItemSearchRoute } from './pages/subscriptionItemSearch';
import { route as customerSearchRoute } from './pages/customerSearch';
import { route as planSearchRoute } from './pages/planSearch';
import { route as customerFeatureSearchRoute } from './pages/customerFeatureSearch';
import { route as featureSearchRoute } from './pages/featureSearch';
import { route as chargeSearchRoute } from './pages/chargeSearch';
import { route as subscriptionCustomerSubscriptionsViewRoute } from './pages/subscriptionCustomerSubscriptionsView';
//Generator1

const routes: RouteItem[] = [
cardSearchRoute,
invoiceSearchRoute,
invoiceItemSearchRoute,
priceSearchRoute,
productSearchRoute,
subscriptionSearchRoute,
subscriptionItemSearchRoute,
customerSearchRoute,
planSearchRoute,
customerFeatureSearchRoute,
featureSearchRoute,
chargeSearchRoute,
subscriptionCustomerSubscriptionsViewRoute,
//Generator2
];

export default routes;