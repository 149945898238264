import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'onka-react-admin-core';
import './index.css';
import App from './App';

var originalLog = console.error;
console.error = function log(...args: any) {
  // TODO: upgrade loadable package
  if (args.length > 0 && typeof args[0] === 'string' && /^Warning: Legacy/.test(args[0])) {
    return;
  }
  originalLog.apply(console, args);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);