// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/ChargeSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'Charge',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'amount',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'amountCaptured',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'amountRefunded',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerId',
      validators: [Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'description',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'paymentMethodId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'receiptUrl',
      validators: [Validators.maxLength(8000)],
      detailComponent: CC.DetailUrlComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'paid',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'refunded',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'currency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'status',
      enumName: 'ChargeStatus',
      enum: paymentApiEnums.ChargeStatus,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'customerId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
        
      }
    }),
    new PageField({
      name: 'description'
    }),
    new PageField({
      name: 'paid',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'refunded',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'status',
      enumName: 'ChargeStatus',
      enum: paymentApiEnums.ChargeStatus,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'customerId',
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'amount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'amountCaptured',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'amountRefunded',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'description',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'paid',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'refunded',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'status',
      enumName: 'ChargeStatus',
      enum: paymentApiEnums.ChargeStatus,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'createdDate',
      dataType: 'string'
    })
  ],
});

