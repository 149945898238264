// Auto generated file
import { globalEnums } from 'onka-react-admin-core';

const adzyApiEnums = {
  ...globalEnums,
  ...{

    CampaignStatus : {
            NotPublished: 1, // 
            Published: 2, // 
    },
    AlgorithmType : {
            Initial: 1, // 
            Second: 2, // 
            Wildcard: 3, // 
    },
  }
}
export default adzyApiEnums