// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/CardSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'Card',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: true,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'addressLine',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'addressCity',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'addressCountry',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'addressState',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'addressZip',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'expireMonth',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'expireYear',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'last4',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'cardHolderName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fingerPrint',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'cvcCheck',
      enumName: 'CardCvcCheck',
      enum: paymentApiEnums.CardCvcCheck,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'livemode',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'brand',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'description',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'funding',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeCustomerId',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'cvcCheck',
      enumName: 'CardCvcCheck',
      enum: paymentApiEnums.CardCvcCheck,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'brand',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'funding',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'last4',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'expireYear',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'expireMonth',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'cvcCheck',
      enumName: 'CardCvcCheck',
      enum: paymentApiEnums.CardCvcCheck,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'description',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    })
  ],
});

