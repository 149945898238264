// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/InvoiceSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'Invoice',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerId',
      validators: [Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'description',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'currency',
      validators: [Validators.maxLength(3)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'status',
      enumName: 'InvoiceStatus',
      enum: paymentApiEnums.InvoiceStatus,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'isLive',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'total',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'tax',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerName',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerAddress',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerEmail',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerPhone',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'country',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'city',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'line1',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'line2',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'postalCode',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'state',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'paid',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'number',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'invoicePdf',
      validators: [Validators.maxLength(8000)],
      detailComponent: CC.DetailUrlComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'hostedInvoiceUrl',
      validators: [Validators.maxLength(8000)],
      detailComponent: CC.DetailUrlComponent,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subtotal',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subscriptionId',
      validators: [Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/SubscriptionSearch',
        filterField: 'id',
        dataField: 'subscription',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'billingReason',
      enumName: 'InvoiceBillingReason',
      enum: paymentApiEnums.InvoiceBillingReason,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subscriptionStripeId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerStripeId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'customerId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
        
      }
    }),
    new PageField({
      name: 'status',
      enumName: 'InvoiceStatus',
      enum: paymentApiEnums.InvoiceStatus,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'isLive',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'number'
    }),
    new PageField({
      name: 'subscriptionId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/SubscriptionSearch',
        filterField: 'id',
        dataField: 'subscription',
        
      }
    }),
    new PageField({
      name: 'billingReason',
      enumName: 'InvoiceBillingReason',
      enum: paymentApiEnums.InvoiceBillingReason,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'createdDate7Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'createdDate8LessEq',
      filterComponent: CC.DateComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'customerId',
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'number',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'total',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'isLive',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'status',
      enumName: 'InvoiceStatus',
      enum: paymentApiEnums.InvoiceStatus,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'tax',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'paid',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'billingReason',
      enumName: 'InvoiceBillingReason',
      enum: paymentApiEnums.InvoiceBillingReason,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'createdDate',
      dataType: 'string'
    })
  ],
});

