// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/ProductSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'Product',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'name',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'description',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'livemode',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'deleted',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'createdDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.required, Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'name'
    }),
    new PageField({
      name: 'description',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    }),
    new PageField({
      name: 'deleted',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      filterComponent: CC.SelectComponent
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'name',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'description',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'deleted',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'createdDate',
      dataType: 'string'
    })
  ],
});

