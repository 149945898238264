// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/PlanSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'Plan',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: true,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'productId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: 'name',
        dataField: 'product',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'amount',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'amountDecimal',
      validators: [Validators.max(79228162514264337593543950335), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'billingScheme',
      enumName: 'PriceBillingScheme',
      enum: paymentApiEnums.PriceBillingScheme,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'active',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'deleted',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'usageType',
      enumName: 'PriceRecurringUsageType',
      enum: paymentApiEnums.PriceRecurringUsageType,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'aggregateUsage',
      enumName: 'PriceRecurringAggregateUsage',
      enum: paymentApiEnums.PriceRecurringAggregateUsage,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'interval',
      enumName: 'PriceRecurringInterval',
      enum: paymentApiEnums.PriceRecurringInterval,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'currency',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'trialPeriodDays',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'intervalCount',
      validators: [Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'livemode',
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'nickname',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'tiersMode',
      enumName: 'PriceTiersMode',
      enum: paymentApiEnums.PriceTiersMode,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.maxLength(50)]
    })
  ],
  filterFields: [
    new PageField({
      name: 'id'
    }),
    new PageField({
      name: 'productId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: 'name',
        dataField: 'product',
        
      }
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'productId',
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: '',
        dataField: 'product',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'amount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'amountDecimal',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'billingScheme',
      enumName: 'PriceBillingScheme',
      enum: paymentApiEnums.PriceBillingScheme,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'active',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'deleted',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'usageType',
      enumName: 'PriceRecurringUsageType',
      enum: paymentApiEnums.PriceRecurringUsageType,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'aggregateUsage',
      enumName: 'PriceRecurringAggregateUsage',
      enum: paymentApiEnums.PriceRecurringAggregateUsage,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'interval',
      enumName: 'PriceRecurringInterval',
      enum: paymentApiEnums.PriceRecurringInterval,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'currency',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'trialPeriodDays',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'intervalCount',
      dataType: 'number'
    }),
    new PageGridField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'nickname',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'tiersMode',
      enumName: 'PriceTiersMode',
      enum: paymentApiEnums.PriceTiersMode,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'stripeId',
      dataType: 'string'
    })
  ],
});

