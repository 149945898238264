// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/SubscriptionItemSearch',
  moduleKey: 'PaymentApi',
  pageKey: 'SubscriptionItem',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: true,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'productId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: 'name',
        dataField: 'product',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'priceId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/PriceSearch',
        filterField: 'id',
        dataField: 'price',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subscriptionId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/SubscriptionSearch',
        filterField: 'id',
        dataField: 'subscription',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'quantity',
      validators: [Validators.required, Validators.max(9223372036854775807), Validators.maxLength(50)],
      createComponent: CC.NumberComponent,
      editComponent: CC.NumberComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'id'
    }),
    new PageField({
      name: 'productId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: 'name',
        dataField: 'product',
        
      }
    }),
    new PageField({
      name: 'priceId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/PriceSearch',
        filterField: 'id',
        dataField: 'price',
        
      }
    }),
    new PageField({
      name: 'subscriptionId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/SubscriptionSearch',
        filterField: 'id',
        dataField: 'subscription',
        
      }
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'productId',
      reference: {
        route: 'PaymentApi/ProductSearch',
        filterField: '',
        dataField: 'product',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'priceId',
      reference: {
        route: 'PaymentApi/PriceSearch',
        filterField: '',
        dataField: 'price',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'subscriptionId',
      reference: {
        route: 'PaymentApi/SubscriptionSearch',
        filterField: '',
        dataField: 'subscription',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'quantity',
      dataType: 'number'
    })
  ],
});

