// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import paymentApiEnums from '../../paymentApiEnums';

export const pageConfig = new PageConfig({
  route: '/PaymentApi/SubscriptionCustomerSubscriptionsView',
  moduleKey: 'PaymentApi',
  pageKey: 'SubscriptionCustomerSubscriptionsView',
  menu: 'PaymentApi',
  menuOrder: 0,
  hideMenu: true,
  get: false,
  edit: false,
  new: false,
  delete: false,
  export: false,
  inlineEditing: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerId',
      validators: [Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'paymentMethodId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'status',
      enumName: 'SubscriptionStatus',
      enum: paymentApiEnums.SubscriptionStatus,
      validators: [Validators.required, Validators.max(255), Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'trialStartDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'trialEndDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'currentPeriodStart',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'currentPeriodEnd',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'latestInvoiceId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'cancelledDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'cancelDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'livemode',
      validators: [Validators.required],
      createComponent: CC.CheckboxComponent,
      editComponent: CC.CheckboxComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'endedDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'startDate',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'nextPendingInvoiceItemInvoice',
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'stripeId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'customerFeatureId',
      validators: [Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'featureId',
      validators: [Validators.maxLength(50)],
      reference: {
        route: 'PaymentApi/FeatureSearch',
        filterField: 'name',
        dataField: 'feature',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'customerId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'PaymentApi/CustomerSearch',
        filterField: 'name',
        dataField: 'customer',
        
      }
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'id',
      isSortable: true,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'status',
      enumName: 'SubscriptionStatus',
      enum: paymentApiEnums.SubscriptionStatus,
      dataType: 'number'
    }),
    new PageGridField({
      name: 'trialStartDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'trialEndDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'currentPeriodStart',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'currentPeriodEnd',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'latestInvoiceId',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'cancelledDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'cancelDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'livemode',
      enumName: 'YesNo',
      enum: paymentApiEnums.YesNo,
      dataType: 'string'
    }),
    new PageGridField({
      name: 'endedDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'startDate',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'featureId',
      reference: {
        route: 'PaymentApi/FeatureSearch',
        filterField: 'name',
        dataField: 'feature',
      },
      dataType: 'string'
    })
  ],
});

